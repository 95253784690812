// Pagination

.pagination {
    &__holder {
        @apply flex justify-end;
    }
}

.pagination__item {
    @apply font-ibm text-dkGrey ml-1.5 text-sm font-semibold text-black block w-9 h-9 flex items-center justify-center border border-solid border-black;

    &.current-page,
    &:hover {
        @apply bg-yellow text-black;
    }
}

.pagination__link {
}

.pagination__link:hover {
}

.pagination__link--active {
}
