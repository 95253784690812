// Fonts

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');
@import url("//hello.myfonts.net/count/400822");

@font-face {
   	font-family: "Quadon-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap; /* Read next point */ 
    unicode-range: U+000-5FF; /* Download only latin glyphs */ 
    src: url('../../webfonts/QuadonMedium/font.woff2') format('woff2'), 
    url('../../webfonts/QuadonMedium/font.woff') format('woff');
}
