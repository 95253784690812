#content {
	.section-p {
		@apply py-10; 

		// &:first-child {
		// 	@apply pt-10;
		// }
		
		&:last-child {
			@apply pb-20;
		}

		@screen lg { 
			@apply py-16;

			&:first-child {
				@apply pt-32;
			}

			&:last-child {
				@apply pb-32;
			}
		}

		&-extra {
			@apply py-20;

			@screen lg { 
				@apply py-28; 
			}
		}

		&-half {
			@apply py-5;

			@screen lg { 
				@apply py-8;

				&:first-child {
					@apply pt-16;
				}

				&:last-child {
					@apply pb-16;
				} 
			}
		}

		&-no-b {
			@apply pb-0;
		}
	}

	.section-m {
		@apply my-10; 

		// &:first-child {
		// 	@apply mt-10;
		// }
		
		// &:last-child {
		// 	@apply mb-0;
		// }

		@screen lg { 
			@apply my-16;

		}

		&-extra {
			@apply my-10;

			@screen lg { 
				@apply my-20; 
			}
		}
	}
	// section.bg-ltGrey + section.bg-ltGrey,
	// section.bg-bgGrey + section.bg-bgGrey,
	// section.bg-ltGrey + section.bg-bgGrey,
	// section.bg-white + section.bg-white{
	// 	@apply -mt-10;

	// 	@screen lg {
	// 		@apply -mt-20;
	// 	}
	// }

	section.bg-bgGrey + section.bg-bgGrey.section-full-img,
	section.bg-bgGrey + section.bg-bgGrey.section-tile-swiper {
		@apply mt-0;

		@screen lg {
			@apply mt-0;
		}
	}

	.section-full-img + .section-tile-swiper,
	.section-full-img + .section-p-extra {
		@apply -mt-10;

		@screen lg {  
			@apply mt-0;
		}
	}

	.section-full-img + .secondary-footer {
		@screen xl { 
			@apply mt-28;
		}
	}

}
