/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-input[type="text"],
.form-input[type="password"],
.form-input[type="number"],
.form-input[type="email"],
.form-select,
textarea.form-input {
    @apply font-ibm border border-black border-solid text-black py-3 px-4 text-lg bg-white rounded-none;

    // &::placeholder {
    //     @apply text-ltGrey;
    // }

    &:focus {
        @apply border-yellow outline-none shadow-none ring-0;
    }

    @screen lg {
        @apply text-lg;
    }

    &.ff-has-errors {
        @apply border-error;
    }
}

.form-select {
    background-image: url(/img/svg/select.svg);
    background-size: 40px;
    background-position: right 5px center;
}

.freeform-label {
    @apply font-ibm text-black text-base flex items-center mb-2;

    &.form-label {
      @apply uppercase font-medium tracking-wider
    }

    input {
      @apply mr-4;
    }

    input[type=checkbox] {
        @apply w-4 h-4 border border-solid border-black cursor-pointer text-black;

        &:checked {
          @apply border-black bg-black shadow-none ring-0 outline-none;
        }
        &:focus {
          @apply outline-none shadow-none ring-0 ring-offset-0;
        }
    }

    input[type=radio] {
        @apply w-4 h-4 border border-solid border-black cursor-pointer text-black;

        &:checked {
          @apply border-black shadow-none ring-0 outline-none bg-black;
        }
        &:focus {
          @apply outline-none shadow-none ring-0 ring-offset-0;
        }
    }
}

label {
    @apply relative;

    span.form-label {
        @apply font-ibm font-bold text-black text-xs absolute uppercase bg-white py-1 px-2 -mt-3 ml-3;
    }
}

.ff-errors {
  li {
    @apply text-error font-bold pl-4 text-sm
  }
}

.ff-form-errors,
.ff-form-success {
  @apply mb-6 font-bold text-center;
}

.freeform-row  {
	@apply hidden;
}