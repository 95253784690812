/*
    to work out font calculations
    if smaller than base = (base font size - target font size) / target font size
    if larger than base = (target font size / base font size)
*/


p, ul, ol, dl, blockquote, pre, dialog {
    @apply leading-normal;
}

a {
    transition: all 0.3s;
}

p {
   @apply mb-4 text-base font-ibm;

    @screen lg {
        @apply text-lg leading-relaxed;
    }

    strong {
         @apply font-bold;
    }

    &:last-child {
        @apply mb-0;
    }

    // a {
    //     @apply underline;

    //     &:hover {
    //         @apply no-underline;
    //     }
    // }

    &.sml {
        @apply leading-tight text-sm;
    }

    &.highlight {
        @apply pl-4 relative tracking-normal;

        @screen lg {
            @apply pl-6;
        }

        &:before {
            content: "";
            @apply bg-yellow h-2 w-2 absolute left-0 mt-2;

            @screen lg {
                @apply h-3 w-3;
                margin-top: 9px;
            }
        }
    }
}

.highlight {
    p {
        @apply text-base font-semibold uppercase leading-tight;

        @screen lg {
            @apply text-xl;
        }
    }
}

.remark {
    p {
        @apply text-xs leading-relaxed;
    }
}

li.highlight,
span.highlight {
    @apply uppercase font-ibm font-semibold relative block;

    @screen lg {
        @apply text-xl;
    }

    &.heading {
        @apply pl-4;

        @screen lg {
            @apply pl-6;
        }

        &:before {
            content: "";
            @apply bg-yellow h-2 w-2 absolute left-0 mt-2;

            @screen lg {
                @apply h-3 w-3;
                margin-top: 9px;
            }
        }
    }
}

.tags {
    @apply flex items-center justify-start -mt-4 mb-6 flex-wrap;

    @screen lg {
        @apply mb-10;
    }

    li {
        @apply mr-2 text-sm font-ibm font-medium mb-2;

		span {
			@apply cursor-default
		}

        a, span {
            @apply py-1.5 px-6 text-black border-black border border-solid bg-white;

            &:hover {
                @apply border-yellow bg-yellow;
            }
        }

        p {
            @apply inline-block text-sm py-1.5 px-6 text-dkGrey border-black border border-solid bg-white;
        }
    }
}

.breadcrumbs {
    @apply flex flex-wrap justify-start;

    &__item {
        @apply flex items-center text-xs font-ibm font-semibold tracking-wider uppercase;

        svg {
            @apply mx-3;
        }

        a {
            @apply text-black;
        }

        .breadcrumbs__link {
            transition: all 0.3s;

            &:hover {
                @apply opacity-50;
            }
        }

        .breadcrumbs__link--active {
            @apply font-bold;
        }
    }

}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    @apply font-quadon;
}

.h1, h1 {
    font-size: calc(2 * theme('fontSize.base-heading-size'));
    @apply leading-none font-medium;

    @screen lg {
        font-size: calc(2.8 * theme('fontSize.base-heading-size'));
    }

    &.banner {
        font-size: calc(2.5 * theme('fontSize.base-heading-size'));

        @screen lg {
            font-size: calc(3.5 * theme('fontSize.base-heading-size'));
        }
    }
}

.h2, h2 {
    font-size: calc(1.5 * theme('fontSize.base-heading-size'));
    @apply leading-tight font-medium;

    @screen lg {
        font-size: calc(2 * theme('fontSize.base-heading-size'));
    }
}

.h3, h3 {
    @apply leading-normal font-medium;
    font-size: calc(1.3 * theme('fontSize.base-heading-size'));

    @screen lg {
        font-size: calc(1.6 * theme('fontSize.base-heading-size'));
    }
}

.h4, h4 {
    @apply leading-normal font-medium;
    font-size: calc(1.1 * theme('fontSize.base-heading-size'));

    @screen lg {
        font-size: calc(1.3 * theme('fontSize.base-heading-size'));
    }
}

.h5, h5 {
    @apply leading-normal font-medium text-xl;

    @screen lg {
        font-size: calc(1.2 * theme('fontSize.base-heading-size'));
    }
}

.h6, h6 {
    @apply text-lg leading-normal font-medium;

    @screen lg {
        @apply text-xl;
    }
}

button,
.button,
.wysiwyg .button,
input[type=submit] {
    transition: all 0.3s;
    @apply cursor-pointer inline-block;
    box-sizing: border-box;

    &:hover,
    &:active,
    &:focus {
        @apply cursor-pointer;

    }

    &.primary {
        @apply inline-flex;

        span {
            &:first-child {
                @apply text-sm bg-yellow text-black px-5 py-3 text-center font-medium uppercase font-ibm tracking-wider whitespace-nowrap;

                @screen lg {
                    @apply px-6 py-4 text-base;
                }
            }

            &.icon {
                @apply bg-yellow bg-no-repeat relative bottom-0 w-11 h-11 ml-2 overflow-hidden;

                @screen lg {
                    @apply w-14 h-14;
                }

                &:after {
                    content: "";
                    background: url('../../img/svg/arrow-right-light.svg');
                    background-size: 16px;
                    background-position: center;
                    @apply absolute w-full h-full bg-no-repeat left-0;

                    @screen lg {
                        background-size: 20px;
                    }
                }

                &.play {
                    &:after {
                        content: "";
                        background: url('../../img/svg/play.svg');
                        background-size: 16px;
                        background-position: center;
                        @apply absolute w-full h-full bg-no-repeat left-0;

                        @screen lg {
                            background-size: 20px;
                        }
                    }
                }

                &.download {
                    &:after {
                        content: "";
                        background: url('../../img/svg/arrow-to-bottom-light.svg');
                        background-size: 16px;
                        background-position: center;
                        @apply absolute w-full h-full bg-no-repeat left-0;

                        @screen lg {
                            background-size: 20px;
                        }
                    }
                }
            }
        }


        &:hover,
        &:active,
        &:focus {

            span {
                &.icon {
                    &:after {
                        animation: buttonAnimation 0.2s 1 ease-out;
                    }
                }

                &.icon.download {
                    &:after {
                        animation: buttonAnimationDown 0.2s 1 ease-out;
                    }
                }
            }
        }
    }

    &.arrow-only {
        @apply inline-flex;

        span {
            &.icon {
                @apply bg-yellow bg-no-repeat relative bottom-0 w-11 h-11 ml-2 overflow-hidden;

                @screen lg {
                    @apply w-14 h-14;
                }

                &:after {
                    content: "";
                    background: url('../../img/svg/arrow-right-regular.svg');
                    background-size: 16px;
                    background-position: center;
                    @apply absolute w-full h-full bg-no-repeat left-0 top-0;

                    @screen lg {
                        background-size: 20px;
                    }
                }
            }
        }

        &.sml {
            span {
                &.icon {
                    @apply w-10 h-10;

                    &:after {
                        background-size: 12px;
                    }
                }
            }
        }


        // &:hover,
        // &:active,
        // &:focus {

        //     span {
        //         &.icon {
        //             &:after {
        //                 animation: buttonAnimation 0.2s 1 ease-out;
        //             }
        //         }
        //     }
        // }
    }


    &.secondary {
        @apply inline-flex text-sm text-white text-center relative font-medium font-ibm uppercase tracking-wider whitespace-nowrap;

        &:after {
            content: "";
            @apply absolute w-full bg-yellow h-px bottom-0 left-0 -mb-2 transition-all duration-300;
        }

        @screen lg {
            @apply text-base;

            &:after {
                @apply -mb-1;
            }
        }

        &:hover,
        &:active,
        &:focus {
            &:after {
                content: "";
                @apply absolute w-full bg-black h-px bottom-0;
            }
        }
    }

    &.secondary-nav {
        @apply inline-flex text-sm text-black items-center text-center relative font-medium font-ibm uppercase tracking-wider whitespace-nowrap;

        &:after {
            content: "";
            @apply absolute w-full bg-yellow h-px bottom-0 left-0 -mb-2 transition-all duration-300;
        }

        @screen lg {
            @apply text-base;

            &:after {
                @apply -mb-1;
            }
        }

        &:hover,
        &:active,
        &:focus {
            &:after {
                content: "";
                @apply absolute w-full bg-black h-px bottom-0;
            }
        }
    }



    &[disabled=disabled],
    &:disabled {
        opacity: 0.3;
        cursor: default;
    }

    // &.link.disabled {
    //  cursor: default;
    //  &:hover {
    //      &:after {
    //          @apply opacity-0 w-full;
    //      }
    //  }
    // }

    &:focus {
        outline: none;
    }
}

@keyframes bigButtonAnimation {
    0% {
        margin-left: 0px;
    }
    49% {
        margin-left: 42px;
    }
    50% {
        margin-left: -42px;
    }
    100% {
        margin-left: 0px;
    }
}

@keyframes bigButtonAnimationReverse {
    0% {
        margin-right: 0px;
    }
    49% {
        margin-right: 42px;
    }
    50% {
        margin-right: -42px;
    }
    100% {
        margin-right: 0px;
    }
}

@keyframes buttonAnimation {
    0% {
        margin-left: 0px;
    }
    49% {
        margin-left: 34px;
    }
    50% {
        margin-left: -34px;
    }
    100% {
        margin-left: 0px;
    }
}

@keyframes buttonAnimationDown {
    0% {
        margin-top: 0px;
    }
    49% {
        margin-top: 34px;
    }
    50% {
        margin-top: -34px;
    }
    100% {
        margin-top: 0px;
    }
}

.share-btn {
     li {

        a {
            @apply bg-black text-white flex items-center justify-center cursor-pointer;

            &:hover {
                @apply bg-yellow;
            }
        }
     }
}

button[type=submit] {
    @apply text-sm bg-yellow text-black px-5 py-3 text-center font-medium uppercase font-ibm tracking-wider whitespace-nowrap;

    @screen lg {
        @apply px-6 py-4 text-base;
    }
}

.link {
    @apply relative;

    &:after {
        content: "";
        position: absolute;
        height: 1px;
        background: #FFF200;
        bottom: -3px;
        transition: all .5s cubic-bezier(1,0,.29,1) .1s;
        right: 0;
        width: 100%;
        opacity: 0;
    }

    &:hover {
        &:after {
            opacity: 100;
        }
    }
}

.wysiwyg-block {

    .table-wrapper {
        @apply w-full overflow-scroll;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
             display: none;
        }
    }

    h1,h2,h3 {

        @apply mb-8 mt-8;

        @screen lg {
            @apply mb-10;
        }

        &:first-child {
            @apply mt-0;
        }
    }

    h4,h5 {
        @apply mb-4 mt-8;

        &:first-child {
            @apply mt-0;
        }
    }

    .highlight + h1,
    .highlight + h2,
    .highlight + h3,
    .highlight + h4,
    .highlight + h5 {
        @apply mt-2;
    }

    ul {
        @apply my-4 pl-4 font-ibm;

        &:last-child {
            @apply mb-0;
        }

        li {
            @apply mb-2 relative text-black;

            @screen lg {
                @apply text-lg;
            }

            &:before {
                content: "";
                @apply absolute bg-border rounded-full;
                width: 4px;
                height: 4px;
                top: 8px;
                left: -16px;

                @screen lg {
                    top: 13px;
                }
            }
        }
    }

    ol {
        @apply font-ibm my-4;
        counter-reset: my-awesome-counter;

        li {
            @apply text-black mb-2;
            counter-increment: my-awesome-counter;

            @screen lg {
                @apply text-lg;
            }

            &:before {
                @apply pr-2 inline-block;
                content: counter(my-awesome-counter) ". ";

                @screen lg {
                    width: 30px;
                }
            }
        }
    }

    a {
        @apply text-dkBlue underline;
    }

    table {
        @apply w-full my-6;

        tr {
            td {
                @apply border-t-0 border-solid border-b border-borderGrey text-black py-6 font-ibm text-sm align-top;

                min-width: 300px;

                @screen lg {
                    @apply text-base;
                }

                &:first-child {
                    @apply uppercase font-bold tracking-wider;

                    @screen lg {
                        min-width: 350px;
                    }
                }

                ul {
                    li {
                        @apply text-base;

                        @screen lg {
                            @apply text-lg;
                        }
                    }
                }

            }

            &:last-child {
                td {
                    @apply border-b-0;
                }
            }
        }

        ul {
            @apply mt-0;
        }

    }


}

.menu-dropdown {
    .wysiwyg-block {
        ul {
            li {
                &:before {
                    @apply hidden;
                }
                a {
                    @apply no-underline text-black;
                    padding: 0 !important;
                }
            }
        }
    }
}
